import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueAnalytics from 'vue-analytics';
import i18n from './i18n'

Vue.use(VueAnalytics, {
  id: 'G-D3PEGGX4C0',
  router
});
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')



