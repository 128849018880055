<template>
  <div class="experts">
    <h2>{{ title }}</h2>
    <div class="experts__description">
      <div class="ml-5">{{ text }}</div>
      <div v-if="title.includes('Kaufkraft berechnen')" class="ml-5 mt-5">
        {{  $t('Die Kaufkraft ist die Summe von Eigenkapital und Bankkredit. Oft deckt die Kaufkraft noch nicht den gesamten Kaufpreis. Die Differenz wird als Finanzierungslücke bezeichnet, die durch Erhöhung des Eigenkapitals oder mit Hilfe eines Finanzierungsmixes geschlossen werden kann.')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DescriptionText",
  props: { title: { required: true }, text: { required: true }, },
};
</script>

<style scoped>
</style>
