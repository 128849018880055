<template>
  <div>
    <form @submit="checkForm" novalidate="true">

      <div class="flex">
        <div class="relative my-1 flex-grow"
          :class="this.$store.state.desktopMode ? 'inline-block' : 'inline-block-mm'">
          <div class="inline-block relative my-1 flex-grow">
            <div v-for="formInput in formInputs" :key="formInput.label" class="input_container icon"
              :class="formInput.value ? 'labelTop' : null">
              <i class="fas fa-fw" :class="formInput.liClass"></i>
              <input v-if="formInput.type != 'text'" v-model="formInput.value" :name=$t(formInput.label)
                :id="formInput.value" class="std" :type="formInput.type" required="required" style="min-width: 320px">
              <label v-if="formInput.type != 'text'" :for="formInput.value">{{ $t(formInput.label) }}</label>
              <textarea v-if="formInput.type == 'text'" v-model="formInput.value" :name=$t(formInput.label)
                :id="formInput.value" class="input std" :type="formInput.type" required="required"
                style="min-width: 320px; min-height: 100px"></textarea>
              <label v-if="formInput.type == 'text'" :for="formInput.value">{{ $t(formInput.label) }}</label>
            </div>
            <vue-element-loading :active="showLoadingBar" :is-full-screen="true" />
            <div style="color: #9e1e1d;">
              <div class="error-wrapper">
                <p v-if="errors.length">
                <ul>
                  <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
                </p>
              </div>
              <div v-if="showSubmitNote == true" style="color: #008000;" class="submitted-note">
                <p>
                  {{ $t('Vielen Dank! Ihre Anfrage wurde erfolgreich versendet.') }} <br />
                  {{ $t('Sie werden in Kürze kontaktiert.') }}
                </p>
              </div>
              <div v-if="submitError == true" style="color: #9e1e1d;" class="error-note">
                <p>
                  {{ $t('Technischer Fehler beim Email-Versand.') }} <br />
                  {{ $t('Keine Daten wurden gespeichert.') }} <br />
                  {{ $t('Bitte kontaktieren Sie den technischen Support:') }} <br />
                  info@companymarket.ch <br />
                  +41 (0) 79 851 55 34
                </p>
              </div>
              <div class="submit-btn-wrapper">
                <button type="submit" class="std prime">{{ $t('Anfrage absenden') }}</button>
              </div>


            </div>
          </div>

        </div>
      </div>

    </form>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import VueElementLoading from "vue-element-loading";

export default {
  name: "FormToEmail",
  props: {},
  components: { VueElementLoading },
  data() {
    return {
      showLoadingBar: false,
      showSubmitNote: false,
      submitError: false,
      errors: [],
      formInputs: {
        vorname: {
          label: "Vorname",
          value: null,
          liClass: "fa-user",
          type: "name",
        },
        name: {
          label: "Name",
          value: null,
          liClass: "fa-chess-pawn",
          type: "name",
        },
        email: {
          label: "Email",
          value: null,
          liClass: "fa-envelope",
          type: "email",
        },
        tel: { label: "Tel", value: null, liClass: "fa-phone", type: "phone" },
        msg: {
          label: "Persönliche Nachricht",
          value: null,
          liClass: null,
          type: "text",
        },
      },
    };
  },
  methods: {
    checkForm: function (e) {
      const regexExpEmail =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
      //const regexExpPhone = /^\+?/;
      this.errors = [];

      if (!this.formInputs.name.value) {
        this.errors.push(this.$i18n.t('Name erforderlich'));
      }

      if (!this.formInputs.vorname.value) {
        this.errors.push(this.$i18n.t('Vorname erforderlich'));
      }

      if (!regexExpEmail.test(this.formInputs.email.value)) {
        this.errors.push(this.$i18n.t('Ungültige Email Adresse'));
      }

      if (!this.formInputs.tel.value) {
        this.errors.push(this.$i18n.t('Telephonnummer erforderlich'));
      }
      e.preventDefault();
      if (this.errors.length === 0) {
        this.submitForm();
      }
    },
    submitForm: function () {
      this.showLoadingBar = true;
      var cmpsNameArr = [];
      var cmpsEmailArr = [];
      var cmpsNameStr = "";
      for (var key in this.$store.state.bankListingCmps) {
        if (this.$store.state.bankListingCmps[key].checked == true) {
          cmpsNameStr = cmpsNameStr.concat(
            this.$store.state.bankListingCmps[key].name,
            "      "
          );
          cmpsNameArr.push(this.$store.state.bankListingCmps[key].name);
          cmpsEmailArr.push(this.$store.state.bankListingCmps[key].email);
        }
      }
      let body = {
        to_email: "gab.voirol@gmail.com", //"carla.kaufmann@companymarket.ch"
        to_email_bbc: [""],
        reply_to: "gab.voirol@gmail.com",
        from_name: this.formInputs.name.value,
        from_vorname: this.formInputs.vorname.value,
        from_email: this.formInputs.email.value,
        from_tel: this.formInputs.tel.value,
        from_msg: this.formInputs.msg.value,
        cmps_names_str: cmpsNameStr,
        cmps_names: cmpsNameArr,
        cmps_emails: cmpsEmailArr, // this is the arrray with all the emails
        ek: this.$store.state.ek,
        bk: this.$store.state.bk,
        kk: this.$store.state.kk,
        kp: this.$store.state.kp,
        fl: this.$store.state.kp - this.$store.state.kk,
        rvn: this.$store.state.rvn,
      };
      emailjs
        .send(
          "zoho",
          "Finanzierungsrechner",
          body,
          "user_WmNF2VkVjJvS14IHLywyB"
        )
        .then(
          (result) => {
            console.log("success: ", result.status, result.text);
            this.showSubmitNote = true;
            this.showLoadingBar = false;
            this.clearForm();
            this.closeSubmitInfoAfterDelay();
          },
          (error) => {
            console.log("error: ", error);
            this.submitError = true;
            this.showLoadingBar = false;
          }
        );
    },
    clearForm() {
      for (var key in this.formInputs) {
        this.formInputs[key].value = null;
      }
      for (key in this.$store.state.bankListingCmps) {
        this.$store.state.bankListingCmps[key].checked = false;
      }
    },
    closeSubmitInfoAfterDelay() {
      setTimeout(() => {
        this.showSubmitNote = false;
      }, 6000);
    },
  },
};
</script>

<style scoped>
.input_container {
  max-width: 80vw;
}

.input_container>input {
  min-width: min(320px, 70vw) !important;
}

.input_container>textarea {
  min-width: min(320px, 70vw) !important;
}

.input_container textarea:focus+label {
  top: 0;
  left: 10px;
  font-size: 12px;
  color: #000;
  padding: 0 4px;
  width: auto;
  min-width: 50px;
  box-shadow: 0 1px 1px #0005;
  border-radius: 2px;
  letter-spacing: 1px;
}

.submitted-note {
  margin-top: 20px;
  border-radius: 10px;
  background-color: #c8f5d5;
  padding: 10px 10px 10px 10px;
}

.error-note {
  margin-top: 20px;
  border-radius: 10px;
  background-color: #f5c8c8;
  padding: 10px 10px 10px 10px;
}

.error-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.submit-btn-wrapper {
  text-align: center;
}
</style>
