import { render, staticRenderFns } from "./Tr.vue?vue&type=template&id=50f4ac41&scoped=true&"
import script from "./Tr.vue?vue&type=script&lang=js&"
export * from "./Tr.vue?vue&type=script&lang=js&"
import style0 from "./Tr.vue?vue&type=style&index=0&id=50f4ac41&prod&scoped=true&lang=css&"
import style1 from "./Tr.vue?vue&type=style&index=1&id=50f4ac41&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f4ac41",
  null
  
)

export default component.exports