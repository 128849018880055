<template>
  <div class="kaufkraft-slider">
    <h2>{{ $t('Kaufpreis:') }}</h2>
    <p v-if="false">
      kKsliderMin: {{ kKSliderMin }}<br>
      kKSliderMax: {{ kKSliderMax }}<br>
      sliderInterval: {{ sliderInterval }}<br>
      sliderMarks: {{ sliderMarks }}<br>
    </p>
    <slider-bar v-if="sliderIsValid" stateName="kp" stateUpdater="updateKp" :sliderMin="kKSliderMin"
      :sliderMax="kKSliderMax" :marks="sliderMarks" :interval="sliderInterval" />
  </div>
</template>

<script>
import SliderBar from "../elements/SliderBar";
import "vue-slider-component/theme/antd.css";
import { calculateIntervalForRange, getNewSteps } from "@/utils/Utils";
import { mapState } from "vuex";
export default {
  name: "KaufkraftSlider",
  components: {
    SliderBar,
  },
  mounted() { },
  data() {
    // var sliderMin = this.$store.state.kKSliderMin;
    // var sliderMax = this.$store.state.kKSliderMax;
    // var interval = calculateIntervalForRange(
    //   this.$store.state.kKSliderMax,
    //   this.$store.state.kKSliderMin
    // );
    return {
      showMarks: true,
      // sliderMin: sliderMin,
      // sliderMax: sliderMax,
      // interval: interval,
      // getSteps: getSteps,
      // calculateIntervalForRange: calculateIntervalForRange,
    };
  },
  watch: {},
  props: {},
  methods: {
    infoBtnClicked() {
    },
  },
  computed: {
    // nextBtnText: {
    //   get() {
    //     if (this.$store.state.kp - this.$store.state.kk > 0) {
    //       return "Lücke schliessen";
    //     } else {
    //       return "Finanzieren";
    //     }
    //   },
    // },
    ...mapState([
      'kKSliderMin',
      'kKSliderMax',
      'desktopMode',
    ]),
    // kp: {
    //   get() {
    //     return this.$store.state["kp"];
    //   },
    //   set(value) {
    //     this.$store.commit("updateKp", value);
    //   },
    // },
    sliderIsValid() {
      return (this.kKSliderMax - this.kKSliderMin) % this.sliderInterval === 0;
    },
    sliderInterval() {
      return calculateIntervalForRange(this.kKSliderMin, this.kKSliderMax)
    },
    sliderMarks() {
      let steps = this.desktopMode ? 10 : 4;
      return getNewSteps(
        this.kKSliderMin,
        this.kKSliderMax,
        steps,
        this.sliderInterval,
        0
      );
    }
  },
};
</script>

<style scoped>
.kaufkraft-slider {
  margin-top: 20px;
  margin-bottom: 30px;
}

.kaufkraft-slider>h2 {
  margin-top: 10px;
  padding-bottom: 20px;
}
</style>
