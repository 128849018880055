<template>
  <div v-if="false">
    <!-- NOT USED ANYMORE -->
    <br />
    <h1>Kaufkraft berechnen</h1>
        <slider-bar
      stateName="kp"
      stateUpdater="updateKp"
      :sliderMin="sliderMin"
      :sliderMax="sliderMax"
      :marks="marks"
      :interval="interval"
    />
    <kapital-listing />
    <h2>Kaufpreis</h2>

    <!--     <button class="std prime min-w-full" @click="$store.commit('nextWindow')">
      {{ nextBtnText }}
    </button> -->
  </div>
</template>

<script>
import SliderBar from "../elements/SliderBar";
import "vue-slider-component/theme/antd.css";
//import { calculateIntervalForRange } from "../../utils/Utils.js";
import KapitalListing from "../blocks/KapitalListing.vue";
//import InputField from "./elements/InputField";

export default {
  name: "KaufkraftSlider",
  components: {
    SliderBar,
    KapitalListing,
    //InputField,
  },
  mounted() {},

  data() {
    //var interval = calculateIntervalForRange(this.$store.state.kKSliderMax, this.$store.state.kKSliderMin);
    return {
      showMarks: true,
    };
  },
  props: {},
  methods: {
    infoBtnClicked() {
      
    },
  },
  computed: {
    nextBtnText: {
      get() {
        if (this.$store.state.kp - this.$store.state.kk > 0) {
          return "Lücke schliessen";
        } else {
          return "Finanzieren";
        }
      },
    },
    ek: {
      get() {
        return this.$store.state.ek;
      },
    },
    bk: {
      get() {
        return this.$store.getters.getBk;
      },
    },
    kk: {
      get() {
        return this.$store.getters.getKk;
      },
    },
    kp: {
      get() {
        return this.$store.state["kp"];
      },
      set(value) {
        this.$store.commit("updateKp", value);
      },
    },
  },
};
</script>

<style scoped>
</style>
