<template>
  <div class="tragkraft-slider">
    <h2>{{ $t('Free Cash Flow: Zur Amortisation verfügbarer Betrag p.a.') }}</h2>
    <p v-if="false">rvn: {{ rvn }}</p>
    <slider-bar v-if="sliderIsValid" stateName="rvn" stateUpdater="updateRvn" :sliderMin="rvnSliderMin"
      :sliderMax="rvnSliderMax" :marks="sliderMarks" :interval="sliderInterval" />
    <h2>{{ $t('Amortisationsregelung – Dept Capacity') }}</h2>
    <table class="ammortisation-table" v-if="false">
      <tr>
        <td v-for="value in tHeading" :key="value" class="table-heading">{{ value }}</td>
      </tr>
      <tr>
        <td v-for="value in tValues" :key="value">{{ value }}</td>
      </tr>
    </table>
    <br>
    <Bar :chart-options="chartOptions" :chart-data="chartData" chart-id="chartId" dataset-id-key="datasetIdKey"
      :plugins="plugins" />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import SliderBar from "../elements/SliderBar";
import {
  calculateIntervalForRange,
  getNewSteps,
  numToK,
} from "@/utils/Utils";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { mapState } from "vuex";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
export default {
  name: "TragkraftSlider",
  props: {
    plugins: {
      legend: {
        display: true,
        labels: {
          color: "rgb(255, 99, 132)",
        },
      },
    },
  },
  mounted() { },
  components: { Bar, SliderBar },
  computed: {
    ...mapState([
      'rvnSliderMin',
      'rvnSliderMax',
      'desktopMode',
      'rvn',
      'bk',
    ]),
    tHeading() {
      return Array.from({ length: 10 }, (_, i) => "Jahr " + (i + 1).toString());
    },
    tValues() {
      return Array.from(
        { length: 10 },
        (_, i) => (this.bk - i * this.rvn) * 0.05
      );
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          y: {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value) {
                return numToK(value) + " CHF";
              },
            },
            title: {
              display: true,
              text: this.$i18n.t('Amortisation / Free Cash Flow'),
            },
          },
        },
      }
    },
    chartData() {
      return {
        labels: [
          "1. " + this.$i18n.t('Jahr'),
          "2. " + this.$i18n.t('Jahr'),
          "3. " + this.$i18n.t('Jahr'),
          "4. " + this.$i18n.t('Jahr'),
          "5. " + this.$i18n.t('Jahr'),
          "6. " + this.$i18n.t('Jahr'),
          "7. " + this.$i18n.t('Jahr'),
          "8. " + this.$i18n.t('Jahr'),
        ],
        datasets: [
          {
            label: "+ 15% ",
            backgroundColor: "rgb(47,7,60)",
            data: Array.from(
              { length: 10 },
              (_, i) =>
                (this.bk - i * this.rvn) * 0.05 * 1.15
            ),
          },
          {
            label:
              this.$i18n.t('ø Gewinn p.a.: ') + this.rvn.toString() + " CHF",
            backgroundColor: "rgb(180, 168, 76)",
            data: Array.from(
              { length: 10 },
              (_, i) =>
                (this.bk - i * this.rvn) * 0.05
            ),
          },
          {
            label: "- 15% ",
            backgroundColor: "rgb(92, 63, 105)",
            data: Array.from(
              { length: 10 },
              (_, i) =>
                (this.bk - i * this.rvn) * 0.05 * 0.85
            ),
          },
        ],
      };
    },
    sliderIsValid() {
      return (this.rvnSliderMax - this.rvnSliderMin) % this.sliderInterval === 0;
    },
    sliderInterval() {
      return calculateIntervalForRange(this.rvnSliderMin, this.rvnSliderMax)
    },
    sliderMarks() {
      let steps = this.desktopMode ? 10 : 4;
      return getNewSteps(
        this.rvnSliderMin,
        this.rvnSliderMax,
        steps,
        this.sliderInterval,
        0
      );
    }
  },
  data() {
    return {
      //showMarks: true,
      //chartOptions: 
    };
  },
};
</script>

<style scoped>
.tragkraft-slider {
  margin-top: 20px;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}

.tragkraft-slider>h2 {
  padding-bottom: 20px;
  margin-top: 10px;
}

.ammortisation-table {
  width: 100%;
}

.table-heading {
  font-weight: bold;
}
</style>
