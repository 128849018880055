<template>
  <div v-if="false">
    <!-- NOT USED ANYMORE -->
    <br />
    <h1>{{ $t('Tragkraft berechnen') }}</h1>
    <kapital-listing />
    <h2>{{ $t('Durchschnittlicher jährlicher Gewinn über 3-5 Jahre') }}</h2>
    <slider-bar stateName="rvn" stateUpdater="updateRvn" :sliderMin="sliderMin" :sliderMax="sliderMax" />
    <h2>{{ $t('Kalkulatorischer Zins') }}</h2>
    <br />
    <table>
      <tr>
        <th v-for="value in tHeading" :key="value">{{ value }}</th>
      </tr>
      <tr>
        <td v-for="value in tValues" :key="value">{{ value }}</td>
      </tr>
    </table>
    <br />
    <LineChartGenerator :chart-options="chartOptions" :chart-data="chartData" chart-id="chartId"
      dataset-id-key="datasetIdKey" :plugins="plugins" />
  </div>
</template>

<script>
import KapitalListing from "../blocks/KapitalListing.vue";
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import SliderBar from "../elements/SliderBar";
//import { calculateIntervalForRange, roundTo1000 } from "../../utils/Utils.js";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
export default {
  name: "TragkraftRechner",
  props: {
    plugins: {
      legend: {
        display: false,
        labels: {
          color: "rgb(255, 99, 132)",
        },
      },
    },
  },
  mounted() { },
  components: { KapitalListing, LineChartGenerator, SliderBar },
  computed: {
    tHeading() {
      return Array.from({ length: 10 }, (_, i) => "Jahr " + i.toString());
    },
    tValues() {
      return Array.from(
        { length: 10 },
        (_, i) => (this.$store.state.bk - i * this.$store.state.rvn) * 0.05
      );
    },
    chartData() {
      return {
        labels: [1, 2, 3, 4, 5, 6, 7, 8],
        datasets: [
          {
            label: "Zins",
            backgroundColor: "#f87979",
            data: Array.from(
              { length: 10 },
              (_, i) =>
                (this.$store.state.bk - i * this.$store.state.rvn) * 0.05
            ),
          },
        ],
      };
    },
  },
  data() {
    /*     var sliderMin = roundTo1000(this.$store.state.rvn / 5);
        var sliderMax = roundTo1000(this.$store.state.rvn * 3);
        var interval = calculateIntervalForRange(
          roundTo1000(sliderMax, sliderMin)
        ); */
    return {
      showMarks: true,
      /* sliderMin: sliderMin,
      sliderMax: sliderMax,
      marks: Array.from(
        { length: 10 },
        (_, i) => ((i + 1) * (sliderMax - sliderMin)) / 10
      ),
      interval: interval, */
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
      },
    };
  },
};
</script>

<style scoped>
table {
  border: 1px solid;
  width: 100%;
}

th {
  border: 1px solid;
}

td {
  border: 1px solid;
}
</style>
